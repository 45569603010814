import PropTypes from "prop-types";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import { setContainerActionOrigin } from "../../../../../actions";
import ListElement from "../../../../../components/ListElement";
import NumberFormatter from "../../../../../libs/format-number";
import themes from "../../../../../libs/themes";
import SerialnumbersBadge from "../../../../../components/SerialnumbersBadge";
import Unit from "../../../../../components/Unit";

const Badge = styled.div`
  border-radius: 1.4rem;
  background-color: #d9e0ec;
  padding: 0.4rem 0.7rem;
  display: inline-block;
  margin-right: 0.4rem;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const Counter = styled.div`
  min-width: 30.4rem;
  font-size: 3.8rem;
  padding-left: 2.4rem;
  height: 4.4rem;
  line-height: 2.8rem;
  display: flex;
  flex-direction: horizontal;
  justify-content: start;
  align-items: baseline;
  &.has-right-border {
    border-right: 0.03rem solid rgba(0, 0, 0, 0.4);
  }
  span {
    font-size: 2.2rem;
  }
`;

function PickingItem({ produced, picking, alertThreshold }) {
  const history = useHistory();
  const { phaseId, sessionId, stepId } = useParams();
  const { container, item, containers, qty } = picking;
  const dispatch = useDispatch();

  if (!container) {
    return (
      <ListElement
        onClick={() => {
          return history.push(
            `/application/supply-item-container/${phaseId}/${sessionId}/${picking._id}/${stepId}`
          );
        }}
        theme={themes.neutralGrey.listElement}
        data-cy="application-supply-item"
        data-cy-id={picking._id}
      >
        <div className="columns is-vcentered is-fullwidth is-marginless">
          <div className="column">
            <p className="subtitle is-6">
              {item.code && (
                <Badge>
                  <i className="icon-product" />
                  {item.code}
                </Badge>
              )}
            </p>

            <p className="title is-4">
              {item?.name}
              {picking.notes?.length > 0 && (
                <small className="subtitle is-6 fg-secondary">
                  {" - "}
                  {picking.notes}
                </small>
              )}
            </p>
          </div>
          <div className="column is-narrow has-text-right"></div>
          <div className="column is-narrow has-text-right">
            <FormattedMessage
              id="app.pages.application.supply.picking_item.no_container_assigned"
              defaultMessage="No container assigned"
            />
          </div>
        </div>
      </ListElement>
    );
  }

  const batchesCount = containers.reduce((acc, c) => {
    if (c.batch && acc.indexOf(c.batch.code) === -1) {
      acc.push(c.batch.code);
    }
    return acc;
  }, []).length;

  const containersCurrent = containers.reduce((acc, c) => acc + c.current, 0);
  const producible = containersCurrent / qty; // How many units can be done with that material
  const theme =
    producible >= alertThreshold
      ? themes.default.listElement
      : themes.errorRed.listElement;

  return (
    <ListElement
      onClick={() => {
        if (container != null) {
          dispatch(setContainerActionOrigin("supply"));
          return history.push(
            `/application/supply-item/${phaseId}/${sessionId}/${picking._id}/${stepId}`
          );
        }
        return history.push(
          `/application/supply-item-container/${phaseId}/${sessionId}/${picking._id}/${stepId}`
        );
      }}
      theme={theme}
      data-cy="application-supply-item"
      data-cy-id={picking._id}
    >
      <div className="columns is-vcentered is-fullwidth is-marginless is-padding">
        <div className="column">
          <p className="subtitle is-6 is-marginless-top">
            {container.serialnumbers && (
              <SerialnumbersBadge serialnumbers={container.serialnumbers} />
            )}
            <Badge>
              <i className="icon-st-containers-icn" />
              {`${container?.name} ${containers.length > 1 ? "..." : ""}`}
            </Badge>

            {item.code && (
              <Badge>
                <i className="icon-product" />
                {item.code}
              </Badge>
            )}
            {container.batch && (
              <Badge>
                <i className="icon-product" />
                {`${container.batch.code} ${batchesCount > 1 ? "..." : ""}`}
              </Badge>
            )}
          </p>
          <p className="title is-4 is-vcentered">
            {item?.name}
            {picking.notes?.length > 0 && (
              <small className="subtitle is-6 fg-secondary">
                {" - "}
                {picking.notes}
              </small>
            )}
          </p>
        </div>
        <Counter className="column is-narrow">
          <Unit>
            <NumberFormatter value={producible} />
            &nbsp;<span>{container.unit}</span>
          </Unit>
        </Counter>
        <Counter className="column is-narrow">
          <Unit>
            <NumberFormatter value={containersCurrent} />
            &nbsp;<span>{container.unit}</span>
          </Unit>
        </Counter>
      </div>
    </ListElement>
  );
}

PickingItem.propTypes = {
  produced: PropTypes.number.isRequired,
  alertThreshold: PropTypes.number.isRequired,
  picking: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    qty: PropTypes.number.isRequired,
    remaining: PropTypes.number,
    container: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      batch: PropTypes.object,
    }),
    item: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      unit: PropTypes.string,
    }).isRequired,
  }).isRequired,
};
export default PickingItem;
