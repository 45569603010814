import { useMutation, useQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";

import {
  setContainerNotes,
  setContainerQty,
  setContainerScrap,
} from "../../../../actions";
import NumberField from "../../../../components/Fields/NumberField";
import TextareaField from "../../../../components/Fields/TextareaField";
import HmiFieldRoute from "../../../../components/HmiFieldRoute";
import onError from "../../../../libs/error-logger";
import intl from "../../../../libs/format-message";
import toast from "../../../../libs/toast";
import { RoundValue } from "../../../../libs/format-number";

const CONTAINER_WITHDRAW = loader("../_shared/Withdraw.mutation.graphql");
const PICKING_ITEM = loader("../_shared/PickingItem.query.graphql");

const Badge = styled.div`
  border-radius: 1.9rem;
  background-color: #eee;
  padding: 0.4rem 1.4rem 0.4rem 0.6rem;
  margin-top: 0.5rem;
  display: inline-block;
  margin-right: 0.4rem;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
  &.error {
    background-color: #f8d6e2;
    color: #d9355b;
  }
`;

function WithdrawContainer() {
  const { containerId, sessionId, pickingId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const container = useSelector(({ container }) => container);
  const [stepIndex, setStepIndex] = useState(0);
  const { hmiConfiguration } = useSelector(({ config }) => config);

  const { data } = useQuery(PICKING_ITEM, {
    variables: { pickingId, sessionId },
  });

  const [mutateWithdraw] = useMutation(CONTAINER_WITHDRAW, {
    variables: { sessionId },
  });

  async function onSubmit() {
    try {
      const variables = {
        containerId: containerId,
        pickingId: pickingId,
        qty: container.qty,
        scrap: container.scrap,
        notes: container.notes,
      };

      await mutateWithdraw({ variables });
      toast({ title: intl.formatMessage({ id: "app.shared.save_success" }) });
      history.go(-2);
    } catch (error) {
      onError(error);
    }
  }

  const item = data?.session.pickingItem.item;

  const subtitle = (
    <Badge>
      <i className="icon-product" /> {item?.code}
    </Badge>
  );

  // Build step sequence accordingly to HMI configuration
  const stepSequence = [0]; // QUANTITY
  if (!hmiConfiguration.SKIP_CONTAINER_SCRAP) stepSequence.push(1); // SCRAP
  if (!hmiConfiguration.SKIP_CONTAINER_NOTES) stepSequence.push(2); // NOTES

  const incStep = (inc = 1) => {
    const newIndex =
      inc > 0
        ? Math.min(stepIndex + 1, stepSequence.length - 1)
        : Math.max(0, stepIndex - 1);
    if (newIndex === 0) {
      history.goBack();
    } else {
      setStepIndex(newIndex);
    }
  };

  return (
    <>
      {/* QUANTITY */}
      {stepSequence[stepIndex] === 0 && (
        <HmiFieldRoute
          title="Quante unità?"
          {...(stepIndex === stepSequence.length - 1
            ? { onSubmit: onSubmit }
            : { onNext: () => incStep() })}
          onBack={() => {
            dispatch(setContainerQty());
            incStep(-1);
          }}
          subtitle2={subtitle}
          field={
            <NumberField
              key="qty"
              autofocus={true}
              placeholder={RoundValue(container.qty)}
              defaultValue={RoundValue(container.qty)}
              onChange={(value) => {
                dispatch(setContainerQty(value));
              }}
            />
          }
        />
      )}
      {/* SCRAP */}
      {stepSequence[stepIndex] === 1 && (
        <HmiFieldRoute
          title="Quanti scarti?"
          {...(stepIndex === stepSequence.length - 1
            ? { onSubmit: onSubmit }
            : { onNext: () => incStep() })}
          onBack={() => {
            dispatch(setContainerScrap());
            incStep(-1);
          }}
          subtitle2={subtitle}
          field={
            <NumberField
              key="scrap"
              autofocus={true}
              placeholder={RoundValue(container.scrap)}
              defaultValue={RoundValue(container.scrap)}
              onChange={(value) => {
                dispatch(setContainerScrap(value));
              }}
            />
          }
        />
      )}
      {/* NOTES */}
      {stepSequence[stepIndex] === 2 && (
        <HmiFieldRoute
          title="Aggiungi una nota"
          {...(stepIndex === stepSequence.length - 1
            ? { onSubmit: onSubmit }
            : { onNext: () => incStep() })}
          onBack={() => {
            dispatch(setContainerNotes());
            incStep(-1);
          }}
          subtitle2={subtitle}
          field={
            <TextareaField
              key="notes"
              autofocus={true}
              placeholder={container.notes}
              defaultValue={container.notes}
              onChange={(value) => {
                dispatch(setContainerNotes(value));
              }}
            />
          }
        />
      )}
    </>
  );
}

WithdrawContainer.propTypes = {};
export default WithdrawContainer;
